import { SetLanguageDto, UpdateProfilePictureDto } from '@memberspot/models';
import { BackendMethod } from '@memberspot/shared/model/backend';

import { CreateProfileDto } from './dtos/create-profile.dto';
import { TermsDto } from './dtos/terms.dto';
import { UpdateAdminLastLoginDto } from './dtos/update-admin-last-login.dto';

export const AdminUserEndpoints = {
  getDemoUserAuthToken: (schoolId: string) =>
    `/admin-users/demo-token/${schoolId}`,
  GET_INTERCOM_SECRET: () => ({
    url: `/admin-users/intercom-secret`,
    method: BackendMethod.GET,
  }),
  GET_UPVOTY_JWT: () => ({
    url: `/admin-users/upvoty-jwt`,
    method: BackendMethod.GET,
  }),
  GET_AHA_JWT: () => ({
    url: `/admin-users/aha-jwt`,
    method: BackendMethod.GET,
  }),
  VERIFY_EMAIL: (email: string) => ({
    url: `/emails/verify/${email}`,
    method: BackendMethod.POST,
  }),
  CREATE_PROFILE: (data: CreateProfileDto) => ({
    url: '/admin-users/create-profile',
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_PROFILE_PICTURE: (data: UpdateProfilePictureDto) => ({
    url: `/admin-users/update-profile-picture`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_LANGUAGE: (data: SetLanguageDto) => ({
    url: `/admin-users/set-language`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_LAST_LOGIN: (data: UpdateAdminLastLoginDto) => ({
    url: `/admin-users/last-login/${data.schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_TERMS: (data: TermsDto) => ({
    url: `/admin-users/terms`,
    data,
    method: BackendMethod.PATCH,
  }),
  GET_USER_TOKEN: (schoolId: string, uid: string) => ({
    url: `/admin-users/user-token/${schoolId}/${uid}`,
    method: BackendMethod.GET,
  }),
  RECALCULATE_PROGRESS: (schoolId: string, uid: string) => ({
    url: `/admin-users/recalculate-progress/${schoolId}/${uid}`,
    method: BackendMethod.POST,
  }),
  GET_SCHOOLS_USER_IS_OWNER_IN: () => ({
    url: `/admin-users/get-user-owner-schools`,
    method: BackendMethod.GET,
  }),
  DELETE_PROFILE: () => ({
    url: `/admin-users/delete-profile`,
    method: BackendMethod.GET,
  }),
};
